import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  computed,
  input,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { isTask } from '@app/_helpers/cosmosTypeCheck';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { createTaskSearch } from '@app/_helpers/search';
import { coerce2DArray, createRxValue, distinctUntilChangedJson, fromRxValue } from '@app/_helpers/utils';
import { clientProjectDialogId } from '@app/shared/dialogs/client-project-picker-stepper-dialog/client-project-picker-stepper-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { pick } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  Logger,
  NotifyService,
  Project,
  ProjectsQuery,
  Task,
  TasksQuery,
  TasksService,
  UserSettingsQuery,
} from 'timeghost-api';
import { TasksStore } from 'timeghost-api/lib/stores/tasks/tasks.store';
const log = new Logger('TaskListComponent');
type SelectedEntity = {
  project: Project;
  task?: Task;
};
@UntilDestroy()
@Component({
  selector: 'tg-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  search = new FormControl('');
  @ViewChild('searchElement', { static: true }) private searchElement: HTMLInputElement;
  focusSearch() {
    this.focusMonitor.focusVia(this.searchElement, this.viewElement.element.nativeElement);
  }
  readonly _selectedEntity = createRxValue<SelectedEntity>(null);
  @Input()
  set selectedEntity(val: SelectedEntity) {
    this._selectedEntity.next(val);
  }
  get selectedEntity() {
    return this._selectedEntity.value;
  }
  readonly selectedEntity$ = this._selectedEntity.asObservable();
  readonly isDefaultProject$ = this.selectedEntity$.pipe(map((x) => !!x.project?.useAsDefault));
  loading = signal(false);
  @Output() selectedEntityChange = new EventEmitter<SelectedEntity>();
  private tasks = createRxValue<Task[]>([]);
  readonly tasks$ = fromRxValue(
    combineLatest([
      this.selectedEntity$.pipe(startWith(this.selectedEntity)),
      this.tasks.asObservable().pipe(startWith(this.tasks.value)),
    ]).pipe(
      startWith([null, []] as [Task, Task[]]),
      distinctUntilChangedJson(([x]) => x?.project?.id),
      map(([selected, tasks]) => {
        let project = selected?.project;
        if (!project?.id) project = this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault, limitTo: 1 })[0];
        if (!project) return null;
        return tasks?.filter((x) => x.project?.id === project.id);
      }),
    ),
    [],
    [],
  );
  readonly entries$filtered = combineLatest([
    this.tasks.asObservable(),
    this.search.valueChanges.pipe(startWith(''), debounceTimeAfterFirst(300), distinctUntilChangedJson()),
    this.selectedEntity$.pipe(startWith(this.selectedEntity), distinctUntilChangedJson()),
  ]).pipe(
    map(([entries, q, selected]: [Task[], string, SelectedEntity]) => {
      if (!q?.length) return [entries, selected];
      return [createTaskSearch(entries).search(q), selected];
    }),
    map(([entries, selected]: [Task[], SelectedEntity]) => {
      return (
        entries
          ?.map((x) => ({
            ...x,
            project:
              (selected.project && pick(selected.project, 'id', 'name', 'completed', 'useAsDefault')) || x.project,
            selected: selected?.task && x.id === selected.task.id,
          }))
          // @ts-ignore
          .sort(firstBy((a, b) => b.selected - a.selected).thenBy((x) => x.name, 'asc'))
      );
    }),
  );
  showProjectClientLabel = input(false);
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  constructor(
    private projectsQuery: ProjectsQuery,
    private tasksQuery: TasksQuery,
    private userSettingsQuery: UserSettingsQuery,
    private tasksService: TasksService,
    private notifyService: NotifyService,
    private router: Router,
    private focusMonitor: FocusMonitor,
    private viewElement: ViewContainerRef,
    private dialogs: MatLegacyDialog,
  ) {}
  ngOnInit(): void {
    let lastProjectId: string;
    this.selectedEntity$
      .pipe(
        switchMap(async ({ project }) => {
          if (!project?.id) project = this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault, limitTo: 1 })[0];
          if (!project) return null;
          if (lastProjectId !== project.id) this.tasks.next([]);
          lastProjectId = project.id;
          const remoteTasks = await this.tasksService.getByProject(project, false).toPromise();
          if (remoteTasks?.length) {
            this.tasks.update(remoteTasks);
            (this.tasksQuery.__store__ as TasksStore).upsertMany(remoteTasks);
          } else this.tasks.next([]);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.notifyService.onMessage
      .pipe(
        filter((x) => x?.payload && isTask(x.payload)),
        switchMap(async (x) => {
          const store = this.tasksQuery.__store__ as TasksStore;
          if ([1, 2].includes(x.type)) {
            const data = coerce2DArray(x.payload);
            store.upsertMany(data);
            if (data?.length) {
              this.tasks.update((s) => {
                data.forEach((d: Task) => {
                  const idx = s.findIndex((x) => x.id === d.id);
                  if (idx === -1) s.push(d);
                  else s.splice(idx, 1, d);
                });
                return s;
              }, true);
            }
          } else if (x.type === 3) {
            const payloadId = x.payload?.id;
            const isSelected = this.selectedEntity.task?.id === payloadId;
            if (isSelected)
              this._selectedEntity.update((s) => {
                s.task = null;
                return s;
              }, true);
            store.remove(payloadId);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
  trackId(i: number, { id }: { id: string }) {
    return id;
  }
  submitTask(entity?: SelectedEntity) {
    this._selectedEntity.update((selected) => {
      if (entity.task?.id === selected.task?.id) selected.task = null;
      else selected.task = entity.task;
      return selected;
    }, true);
    this.selectedEntityChange.emit(this.selectedEntity);
  }
  isProjectPinned = computed(() => {
    const user = this.userSettingsQuery.getValue();
    return !!user.pinnedProjects?.find((x) => x === this.selectedEntity?.project?.id);
  });
  projectMenuPosition = { x: '0px', y: '0px' };
  openContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    this.projectMenuPosition.x = event.clientX + 'px';
    this.projectMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  openProject(id: string) {
    return this.router
      .navigate(['/settings/projects', id])
      .then(() => this.dialogs.getDialogById(clientProjectDialogId)?.close());
  }
}
